const exploreSectiontwo = [
  {
    id: '1',
    percentage: '70%',
    description: 'Faster onboarding process',
    bgColor: 'primary.redpink',
  },
  {
    id: '2',
    percentage: '90%',
    description: 'Improvement in best candidate matches',
    bgColor: 'primary.redpinkthree',
  },
];

export default exploreSectiontwo;
