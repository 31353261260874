const whyrazor = [
  {
    title: 'WHY RAZORSWIFT',
    description:
      "We are an innovative digital marketplace that leverages AI and a comprehensive knowledge partner ecosystem to empower aspiring individuals and streamline talent acquisition for businesses in India. Through our unique 'Pathways' approach, we offer tailored journeys for individuals to excel within the evolving hiring landscape, while also providing businesses with structured processes for aligning competencies and efficient curation.",
    button: 'Explore',
    link: '/aboutus',
  },
];
export default whyrazor;
