const dropDownData = [
  {
    id: "1",
    title: "Looking to hire top talent ?",
  },
  {
    id: "2",
    title: "Aspiring for a dream career ?",
  },
  {
    id: "3",
    title: "Looking to partner and collaborate ?",
  },
];

export default dropDownData;
