import CustomImage from '@/components_fbl/globalComponents/CustomImage/CustomImage';
import TertiaryHeading from '@/components_fbl/headingComponents/TertiaryHeading';
import businessAdvantagesData from '@/src/constants/Homepage/businessAdvantagesData';
import { Box, Grid } from '@mui/material';
import Link from '@mui/material/Link';
const BusinessAdvantages = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row', lg: 'row' },
        height: { xs: '', md: '' },
        padding: '15px 15px 0px 15px',
        justifyContent: {
          xs: 'flex-start',
          md: 'center',
        },
        gap: { md: '60px' },
        alignItems: { xs: 'flex-start', md: 'center', lg: 'center' },
        backgroundColor: '#EE5164',
        marginTop: '50px',
        marginBottom: '50px',
        borderRadius: '20px',
        ml: '20px',
        mr: '20px',
      }}
    >
      <Grid sx={{ alignItems: 'center' }} container spacing={1}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TertiaryHeading
            sx={{
              color: 'white',
              marginTop: { xs: 2, md: 0 },
            }}
          >
            {businessAdvantagesData.title}
          </TertiaryHeading>
          <Link
            sx={{
              textDecoration: 'none',
              backgroundColor: 'white',
              color: '#EE5164',
              cursor: 'pointer',
              fontSize: '16px',
              fontWeight: '600',
              width: 'fit-content',
              padding: '10px 30px',
              borderRadius: '50px',
              marginBottom: { xs: 2, md: 0 },
              border: '1px solid #ffffff',
              '&:hover': {
                color: 'white',
                backgroundColor: '#EE5164',
              },
            }}
            href={businessAdvantagesData.button.link}
          >
            {businessAdvantagesData.button.title}
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ display: 'flex', justifyContent: { sm: 'start', md: 'end' } }}
        >
          <CustomImage
            alt="lookingtoimg"
            src={businessAdvantagesData.image}
            width={'clamp(150px, 15vw, 200px)'}
            aspectRatio={'1/1'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessAdvantages;
